export function checkMajor(list) {

    let message = '';

    // 用户填了几个阶段
    let normalNum = 0;

    // 用户已经填了的阶段中，所有有技术3分包的阶段，分包占比之和不能超过100，小于0
    let subpackageCost = 0;

    checkLoop: for (let i = 0; i < list.length; i++) {
        let phase = list[i];

        // 如果当前阶段不可配置，跳过校验
        if (!phase.configurable) {
            continue;
        } else {
            normalNum++
        }

        if (Array.isArray(phase.subCostQuotaList) && phase.subCostQuotaList.length > 0) {
            // quota // 关联的专业在这个阶段下的比重
            phase.subCostQuotaList.forEach(sub => {
                subpackageCost += sub.quota
            })

            // 校验这个分包关联的专业用户有没有填，如果有部分子项没选择带分包的专业，所有带分包的专业比例 js3CostQuota 必须为 100%

            for (let x = 0; x < phase.subCostQuotaList.length; x++) {
                const costObj = phase.subCostQuotaList[x];

                if (Array.isArray(phase.majorGroupList) && phase.majorGroupList.length > 0) {

                    let costRatio = 0;

                    for (let y = 0; y < phase.majorGroupList.length; y++) {
                        const groupObj = phase.majorGroupList[y];

                        groupObj.list.forEach(groupItem => {
                            if (groupItem.type === costObj.major && groupItem.js3CostQuota) {
                                costRatio += groupItem.js3CostQuota;
                            }
                        })
                    }

                    // 如果这项技术3分包在子项的专业列表中不存在，直接报错
                    if (costObj.quota > 0 && Math.abs(costRatio - 100) > 0.000001) {
                        message = `${phase.phaseName}阶段专业分包成本比例不等于100%`
                        break checkLoop;
                    }
                } else {
                    message = `${phase.phaseName}阶段专业分包成本未承担`
                    break checkLoop;
                }

            }

        }



        // 算出剩余占比
        // let ratio = 0; // 总比例
        // phase.twoList.forEach((item) => {
        //     ratio += item.allocateQuota;
        // });
        // const surplusMoney = phase.quota * (100 - ratio) * 0.01;

        // let tecMoney = 0;

        // if (phase.tec) {
        //     if (phase.tec.allocateAmount) {
        //         tecMoney = phase.tec.allocateAmount * 10000
        //     } else {
        //         if (phase.tec.allocateQuota > 100 || phase.tec.allocateQuota <= 0) {
        //             message = `${phase.phaseName}阶段-技术经济-金额占比不正确！`
        //             break checkLoop;
        //         }
        //         tecMoney = surplusMoney * phase.tec.allocateQuota * 0.01 // 技术经济的钱
        //     }
        // }

        // 专业剩余占比的钱 = 剩余的钱 - 技术经济的钱
        // let AllMajorMoney = surplusMoney - tecMoney
        // if (AllMajorMoney < 0) {
        //     message = `${phase.phaseName}阶段-技术经济-金额不正确！`
        //     break checkLoop;
        // }

        // 校验子项、其他的比例之和是否满足100
        let ratio1 = 0;
        if (Array.isArray(phase.majorGroupList)) {
            phase.majorGroupList.forEach(item => {
                ratio1 += item.majorGroupQuota
            })
        }
        if (Array.isArray(phase.otherList)) {
            phase.otherList.forEach(item => {
                ratio1 += item.allocateQuota
            })
        }

        if (Math.abs(100 - ratio1) > 0.000001) {
            message = `${phase.phaseName}阶段-所有子项-金额占比不正确！`
            break checkLoop;
        }


        // 校验所有子项中每个子项的专业之和是否为100，以及每个专业下分配的人员的钱是否等于这个专业的金额
        if (Array.isArray(phase.majorGroupList)) {
            for (let j = 0; j < phase.majorGroupList.length; j++) {
                const group = phase.majorGroupList[j] // 获取一个子项

                let majorRatio = 0; // 算出这个子项下，所有专业的占比和
                if (Array.isArray(group.list)) {
                    for (let x = 0; x < group.list.length; x++) {
                        const item = group.list[x]
                        majorRatio += item.allocateQuota
                    }
                }

                if (majorRatio === 0) {
                    message = `请配置${phase.phaseName}阶段-${group.majorGroup}下的专业！`
                    break checkLoop;
                }

                console.log('各个专业比例和：', majorRatio)
                if (Math.abs(100 - majorRatio) > 0.000001) {
                    message = `${phase.phaseName}阶段-${group.majorGroup}-各个专业金额占比不正确！`
                    break checkLoop;
                }
            }
        }

    }

    // subpackageCost 用户填过的阶段中，所有的成本之和
    // 如果全部填完，那么之和必须等于技术3分包的数量 * 100
    console.log('normalnum', normalNum, subpackageCost)
    let length = 0;
    list.forEach(item => {
        if (Array.isArray(item.subCostQuotaList)) {
            length = item.subCostQuotaList.length;
        }
    })
    if (normalNum === list.length) {
        // 所有的阶段都填完了，技术3分包比例必须得是100%
        if (subpackageCost !== 100 * length) {
            message = `所有阶段各专业成本占比不正确！`
        }
    } else {
        // 没全部填完，就只校验是不是在 100 * n 之内即可
        if (subpackageCost > 100 * length || subpackageCost < 0) {
            message = `各专业成本占比不正确！`
        }
    }

    return message;
}